import React from "react"
import { Link } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import "../components/layout.css"
import Layout from "../components/layout"

const stripePromise = loadStripe(
  "pk_live_51Hj7BPJU3pXRkN5Sj9G4pmaY1J6unRwv2zsRG9TmyAkvjRJrQvNsypKO2JBoNeVZIAT9fdm7RI2PL84w13Xd88ER00eGRp7cNY"
)

const BuyPage = () => {
  const stripeCheckout = async (event, code) => {
    event.preventDefault()

    const stripe = await stripePromise

    const { error } = await stripe.redirectToCheckout({
      mode: "subscription",
      lineItems: [{ price: code, quantity: 1 }],
      successUrl: `https://www.explot.io/success`,
      cancelUrl: `https://www.explot.io/buy?cancel`,
    })

    if (error) {
      alert(`An error occured ${error.message}`)
    }
  }

  return (
    <Layout>
      <div className="w-full min-h-screen gradient md:p-16">
        <div>
          <h1 className="text-center text-white">Subscribe to Explot</h1>
          <p className="text-center text-white">
            Support Explot by subscribing and gain access to features as they
            are released.{" "}
          </p>
        </div>

        <div className="w-5/6 md:w-1/2 mx-auto shadow-custom p-4 rounded bg-white">
          <ul className="md:grid md:grid-cols-1 m-0 md:m-4 md:p-4 md:divide-x md:divide-gray-400 ">
            <li className="md:mx-2 md:px-4">
              <h2 className="text-center mb-1">
                <svg
                  className="h-6 w-6 mr-2 inline"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#24B47E"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Pro Package
              </h2>
              <p className="text-sm py-4 h-28">
                Quickly visualize data with the pro subscription. Explore and
                gather insights from your files and visualize them effortlessly.
                <i>
                  {" "}
                  Contains all the features from the{" "}
                  <a
                    className="underline"
                    target="_blank"
                    href="https://www.explot.io/tools/file-info"
                  >
                    File Info tool
                  </a>
                </i>
                . And much more.
              </p>
              <p className="font-normal text-2xl text-center border-b-2 border-t-2 py-2">
                $ 9
                <code className="font-thin text-sm color-gray-400">
                  {" "}
                  per month
                </code>
              </p>
              <ul className="ml-0">
                <li>
                  <button
                    onClick={e =>
                      stripeCheckout(e, "price_1IGqr3JU3pXRkN5Sbd7ndqdz")
                    }
                    className="mx-auto shadow px-2 py-1 w-full border border-transparent text-base leading-6 rounded text-white bg-dark-green hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
                  >
                    Start Trial
                  </button>
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 mr-1"
                    stroke="green"
                    fill="green"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                  Upload CSV Files
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 mr-1"
                    stroke="green"
                    fill="green"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                  Table View of Your Files, with stats about them.
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 mr-1"
                    stroke="green"
                    fill="green"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                  Basic Charts (line,bar and scatter)
                </li>{" "}
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 mr-1"
                    stroke="green"
                    fill="green"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                  Basic Chart Aggregation (max,min,avg,count)
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 mr-1"
                    stroke="green"
                    fill="green"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                  Up to 10 cells (with up to 10 different datasets)
                </li>
                <h3 className="text-left text-sm pt-4 pb-1 mb-1">Future</h3>
                <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    className="w-3 h-3 mr-1"
                  >
                    <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                  </svg>
                  Basic Data Transformation
                </li>
                <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    className="w-3 h-3 mr-1"
                  >
                    <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                  </svg>
                  Additional Charts (pie, area)
                </li>
                <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    className="w-3 h-3 mr-1"
                  >
                    <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                  </svg>
                  Much More...
                </li>
              </ul>
            </li>
          </ul>
          <div className="w-full mx-auto">
            <a
              href="/faq/"
              target="_blank"
              className="mx-auto shadow px-2 py-1 w-1/3 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
            >
              F.A.Q
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const EarlyAccessOld = () => {
  return (
    <div className="w-full h-screen gradient md:p-16">
      <h1 className="text-center text-white">Get early access</h1>
      <p className="text-center text-white">
        Buy explot and start exploring your data.
      </p>
      <form
        method="post"
        name="buy-eary-access-step-1"
        className="w-4/5 md:w-2/3  max-w-lg mx-auto shadow-custom p-8 rounded bg-white"
        action="/checkout/"
      >
        <input
          type="hidden"
          className="hidden"
          name="form-name"
          value="buy-eary-access-step-1"
        />
        <label className="md:w-2/3 block text-gray-500 font-bold mr-3 px-2 mb-8">
          <input
            className="mr-2 leading-tight"
            name="buy-early-access"
            type="checkbox"
          />
          <span className="text-sm">
            Buy early access to explot for a one time payment of $49.
          </span>
        </label>
        <Link
          to="/checkout/"
          className="py-2 flex-shrink-0 bg-dark-green hover:shadow-custom border-dark-green hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
        >
          Continue to checkout
        </Link>
        <Link
          to="/"
          className="flex-shrink-0 border-transparent border-4 text-green text-sm py-1 px-2 rounded"
        >
          Cancel
        </Link>
      </form>
    </div>
  )
}

export default BuyPage
